<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card
    v-if="$route.query.id"
    :key="noteKey"
    class="encounter-note"
  >
    <!-- title -->
    <v-card-title class="d-print-none">
      <!-- Close -->
      <btn
        label="Close"
        color="secondary"
        :icon="icons.mdiClose"
        @click="closeEncounterNote"
      ></btn>

      <v-spacer></v-spacer>
      {{ title }}
      <v-spacer></v-spacer>

      <!-- Calculate Payment -->
      <btn
        label="Calculate"
        color="success"
        class="encounter-note-sign-sync"
        offline-disable
        :icon="icons.mdiCurrencyUsd"
        @click="calculatePayment"
      ></btn>
    </v-card-title>

    <Attachments
      :is-signed="encounter.is_signed"
      :disabled="addingAddendum"
      :patient-id="patient.id"
      class="d-print-none"
      :encounter-attachments.sync="patient.all_attachments"
    ></Attachments>

    <v-card-actions class="d-print-none">
      <!-- Redact -->
      <btn
        v-if="!isRedacted"
        label="Redact"
        color="accent"
        :icon="icons.mdiMarker"
        :disabled="!encounter.is_signed || addingAddendum"
        @click="toggleRedact"
      ></btn>

      <!-- Unredact -->
      <btn
        v-else
        label="Unredact"
        color="secondary"
        outlined
        :icon="icons.mdiMarkerCancel"
        :disabled="!encounter.is_signed || addingAddendum"
        @click="toggleRedact"
      ></btn>

      <!-- Print -->
      <btn
        label="Print"
        color="primary"
        :icon="icons.mdiPrinter"
        :disabled="!encounter.is_signed || addingAddendum"
        @click="printNote"
      ></btn>

      <!-- PDF -->
      <!--
      <btn
        label="Create PDF"
        color="primary"
        :icon="icons.mdiFileDocumentMultiple"
        :disabled="!encounter.is_signed || addingAddendum"
        @click="getPDF"
      ></btn>
      -->

      <v-spacer></v-spacer>

      <!-- Sign Note -->
      <btn
        v-if="!encounter.is_signed"
        label="Sign Note"
        color="warning"
        class="encounter-note-sign-sync"
        :icon="icons.mdiFileSign"
        :disabled="!encounter.is_validated || addingAddendum || (encounter.is_deferred_care && !encounter.deferred_care_reason)"
        @click="signNote"
      ></btn>

      <!-- Unsign Note -->
      <btn
        v-else-if="!encounter.is_synced"
        label="Unsign Note"
        color="warning"
        class="encounter-note-sign-sync"
        :icon="icons.mdiUndoVariant"
        :disabled="encounter.is_synced || addingAddendum"
        @click="unsignNote"
      ></btn>

      <!-- Show signed status -->
      <icon-value
        v-else
        :icon="encounter.is_signed ? icons.mdiClipboardCheck : icons.mdiCloseCircle"
        :value="encounter.is_signed ? 'Signed' : 'Not Signed'"
        :color="encounter.is_signed ? 'success' : 'error'"
        class="encounter-note-sign-sync"
      ></icon-value>

      <!-- Sync Note -->
      <btn
        v-if="encounter.is_signed && !encounter.is_synced"
        label="Sync Note"
        color="error"
        class="encounter-note-sign-sync"
        :icon="icons.mdiFileSync"
        :disabled="!encounter.is_signed || encounter.is_synced || addingAddendum"
        offline-disable
        @click="syncNoteQuery"
      ></btn>

      <!-- Show synced status -->
      <icon-value
        v-else
        :icon="encounter.is_synced ? icons.mdiCloudCheckVariant : icons.mdiCloseCircle"
        :value="encounter.is_synced ? 'Synced' : 'Not Synced'"
        :color="encounter.is_synced ? 'success' : 'error'"
        class="encounter-note-sign-sync"
      ></icon-value>
    </v-card-actions>

    <!-- Redact Heading -->
    <v-expand-transition>
      <div v-show="isRedacted">
        <NoteSection
          label="Redacted Document"
          class="mt-1 mb-3"
          heading
        ></NoteSection>
      </div>
    </v-expand-transition>

    <v-card-text class="printHTML px-5">
      <table class="header-table">
        <tr>
          <td colspan="2">
            <table>
              <tr>
                <td>
                  <img
                    class="logo"
                    src="/img/logo/swc-black-blue.png"
                  >
                </td>
                <td colspan="2">
                  <div class="title">
                    <span v-if="encounter.is_revised">
                      REVISED<br>
                    </span>
                    {{ title }}
                  </div>
                  <div class="vid">
                    <label>VID:</label>
                    <span :class="{'redacted-text': isRedacted}">
                      {{ encounter.id }}
                    </span>
                  </div>
                  <div class="vid">
                    {{ providerName(encounter.created_by_user_id) }}
                  </div>
                </td>
              </tr>
            </table>
          </td>
          <td width="25%">
            <label>DATE OF SERVICE</label><br>
            <b>{{ formatDate(encounter.visit_date) }}</b>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>FACILITY</label><br>
            <span v-if="encounter.place_of_service_id"><b>{{ $store.getters['facilities/getById'](encounter.place_of_service_id).title }}</b></span>
          </td>
          <!--
          <td>
            <label>FAX</label><br>
            <span :class="{'redacted-text': isRedacted}"><b>123-456-7890</b></span>
          </td>
          -->
          <td>
            <label>VISIT TYPE</label><br>
            <b>{{ encounter.visit_type }}</b>
          </td>
        </tr>
        <tr>
          <td>
            <label>PATIENT</label><br>
            <span :class="{'redacted-text': isRedacted}"><b><patient-name :patient="patient"></patient-name></b></span>
          </td>
          <td>
            <label>GENDER</label><br>
            <span v-if="patient.gender"><b>{{ patient.gender }}</b></span>
          </td>
          <td>
            <label>DATE OF BIRTH</label><br>
            <span v-if="patient.dob"><b>{{ formatDate(patient.dob) }}</b></span>
          </td>
        </tr>
      </table>

      <!-- Deferred Care -->
      <div v-if="encounter.is_deferred_care">
        <div v-if="encounter.is_signed">
          <label>Deferred Care Reason:</label> {{ encounter.deferred_care_reason }}<br>
        </div>
        <text-area
          v-else
          v-model="encounter.deferred_care_reason"
          label="Reason patient care was deferred..."
          style="font-family: Inter, sans-serif;"
          required
          @input="deferredCareUpdated"
        ></text-area>
      </div>

      <!-- Content -->
      <div
        v-else
        v-html="encounter.final_note"
      ></div>

      <!-- Signed -->
      <div v-if="encounter.is_signed">
        <hr>
        Reviewed and electronically signed by:
        {{ providerName(encounter.signed_by_user_id) }}
        on {{ formatDateTime(encounter.signed_time) }}<br>
      </div>

      <!-- Removed TXP-181, added to final_note column instead
        Addendums
      <div
        v-if="encounter.addendums && encounter.addendums.length > 0"
        class="addendums"
      >
        <hr>
        <label>Addendum{{ encounter.addendums.length > 1 ? 's' : '' }}:</label><br>
        <div
          v-for="(item, index) in sortedAddendums"
          :key="index"
        >
          <br>{{ item.notes }}<br>
          Addendum added by:
          {{ providerName(encounter.created_by_user_id) }}
          on {{ formatDateTime(item.created) }}<br>
        </div>
      </div>-->

      <!-- Footer -->
      <div class="footer">
        <hr>
        <div>{{ appName }} © {{ $date().format('YYYY') }} | (866) 968-6380 | (310) 445-5999</div>
        <div>12021 Wilshire Blvd. #745, Los Angeles, CA 90025</div>
      </div>
    </v-card-text>
    <v-card-actions
      v-if="addingAddendum"
      class="d-print-none pb-0"
    >
      <text-area
        v-model="addendum"
        label="Enter addendum..."
        class="col-sm-12"
      ></text-area>
    </v-card-actions>
    <v-card-actions
      v-if="addingAddendum"
      class="d-print-none"
    >
      <!-- Cancel Addendum -->
      <btn
        label="Cancel"
        color="secondary"
        :icon="icons.mdiCancel"
        @click="cancelAddendum"
      ></btn>
      <v-spacer></v-spacer>
      <!-- Save Addendum -->
      <btn
        label="Save Addendum"
        color="error"
        :icon="icons.mdiContentSaveEdit"
        :disabled="!addendum"
        @click="saveAddendum"
      ></btn>
    </v-card-actions>
    <v-card-actions
      v-else
      class="d-print-none"
    >
      <!-- Close -->
      <btn
        label="Close"
        color="secondary"
        :icon="icons.mdiClose"
        @click="closeEncounterNote"
      ></btn>
      <!-- Delete Deferred Care Note -->
      <btn
        v-if="encounter.is_deferred_care"
        label="Delete"
        color="error"
        :icon="icons.mdiTrashCan"
        :disabled="encounter.is_signed"
        @click="deleteDeferredEncounterNote"
      ></btn>
      <v-spacer></v-spacer>
      <!-- Add Addendum -->
      <btn
        label="Add Addendum"
        color="success"
        :icon="icons.mdiNotePlus"
        :disabled="!encounter.is_synced"
        @click="addAddendum"
      ></btn>
    </v-card-actions>
    <!-- dump object -->
    <div
      v-if="$authUser.user() && $authUser.user().is_superuser"
      class="btn-top-center"
    >
      <btn
        label="Dump"
        :icon="icons.mdiDownload"
        x-small
        color="secondary"
        @click="$store.commit('encounters/updateEncounter', { encounter, patient });
                $router.push({ name: 'dump-encounter', query: { id: encounter.id } })"
      ></btn>
    </div>
  </v-card>
</template>

<script>
import PrimaryCare from '@/mixins/practice-types/PrimaryCare'
import Encounter from '@/mixins/Encounter'
import EncounterNote from '@/mixins/EncounterNote'
import PrimaryCareFinalNote from '@/mixins/final-note/PrimaryCare'

export default {
  mixins: [PrimaryCare, Encounter, EncounterNote, PrimaryCareFinalNote],
  data() {
    return {
      final_note: null,
    }
  },
  computed: {
    title() {
      if (this.encounter.is_deferred_care) return 'Primary Care Deferred Encounter'

      return this.encounter.visit_type === 'New'
        ? 'Primary Care Assessment'
        : 'Primary Care Follow-up Assessment'
    },
    sortedAddendums() {
      const addendums = [...this.encounter.addendums]

      return addendums.sort((a, b) => (a.created > b.created && 1) || -1)
    },
  },
  watch: {
    final_note() {
      if (
        (!this.encounter.is_signed || this.$custom.isEmpty(this.encounter.final_note))
        && this.final_note !== null
      ) {
        this.encounter.final_note = this.final_note
          .replaceAll('. ', '.&nbsp;')
          .replaceAll('<br>\t', '<br>&nbsp;&nbsp;')
      }
    },
  },
  mounted() {
    if (!this.encounter.is_deferred_care) {
      this.generateFinalNote()
    }
  },
  methods: {
    providerName(id) {
      const provider = this.$store.getters['users/getById'](id)

      if (provider) {
        const authId = this.$store.getters['auth/user'].id
        const userSuffix = id === authId ? this.$store.getters['auth/getSuffix'] : ''

        if (userSuffix) {
          return `${provider.first_name} ${provider.last_name}, ${userSuffix}`
        } else {
          return `${provider.first_name} ${provider.last_name}`
        }
      } else {
        return 'Unknown Provider'
      }
    },
  },
}
</script>
